<template>
    <div>
        <div class="row mx-0">
            <div class="col-12 col-lg-10">
                <draggable
                v-model="preguntas"
                class=""
                :disabled="!enabled"
                ghost-class="ghost"
                handle=".set-draggable"
                @start="dragging = true"
                @end="dragging = false"
                >
                    <div
                    v-for="(pregunta,i) in preguntas"
                    :key="i"
                    class="card-pregunta-encuesta p-2 mb-3"
                    :style="`${pregunta.error ? 'border: 2px solid red' : ''}`"
                    >
                        <!-- n pregunta -->
                        <div class="row mx-0 mb-2">
                            <div class="col-12 position-relative">
                                <div class="n-pregunta">
                                    {{ i + 1 }}
                                </div>
                                <div class="set-draggable">
                                    <i class="icon-vertical-points cr-pointer text-gris" />
                                    <i class="icon-vertical-points cr-pointer text-gris" />
                                </div>
                            </div>
                        </div>
                        <div class="row mx-0">
                            <!-- tipo -->
                            <div class="col-12 col-md-3">
                                <el-select
                                v-model="pregunta.tipo"
                                placeholder="Tipo de Pregunta"
                                size="small"
                                class="w-100"
                                >
                                    <el-option
                                    v-for="item in tipoPreguntaSelect"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                    >
                                        <span style="float: left">
                                            <i v-if="item.value === 1" class="icon-mensaje" />
                                            <i
                                            v-if="item.value === 2"
                                            class="icon-confirmado"
                                            />
                                            <i v-if="item.value === 3" class="icon-vertical-points" />
                                            {{ item.label }}
                                        </span>
                                    </el-option>
                                </el-select>
                            </div>
                            <!-- pregunta -->
                            <div class="col-12 col-md-6 col-lg-6 my-2 my-md-0">
                                <el-input
                                v-model="pregunta.titulo"
                                placeholder="Pregunta"
                                type="textarea"
                                class="mb-1"
                                show-word-limit
                                maxlength="250"
                                />
                            </div>
                            <!-- obligatorio/eliminar -->
                            <div class="col-12 col-md-3 col-lg-3 my-auto">
                                <div class="d-middle-center">
                                    <p class="f-15">Obligatoria</p>
                                    <ValidationProvider tag="div">
                                        <el-switch
                                        v-model="pregunta.obligatoria"
                                        :active-value="1"
                                        :inactive-value="0"
                                        active-color="#00D9B5"
                                        inactive-color="#E6E6E6"
                                        class="mx-2"
                                        />
                                    </ValidationProvider>
                                    <i
                                    class="icon-delete-outline cr-pointer"
                                    @click="eliminarPreguntaModal(pregunta, i)"
                                    />
                                </div>
                            </div>
                        </div>
                        <div v-if="pregunta.tipo === 2" class="row mx-0 mt-2">
                            <!-- tipo -->
                            <div class="col-3">
                                <p class="color-general f-500 cr-pointer text-verde" @click="agregarOpcion(pregunta)">
                                    Agregar respuesta
                                </p>
                            </div>
                            <!-- pregunta -->
                            <div class="col-7">
                                <draggable
                                v-model="pregunta.opciones"
                                class=""
                                handle=".icon-vertical-points"
                                :disabled="!enabled"
                                @start="dragging = true"
                                @end="dragging = false"
                                >
                                    <div v-for="(opcion, o) in pregunta.opciones" :key="o" class="d-flex mb-2">
                                        <i
                                        class="icon-vertical-points cr-pointer text-gris my-auto"
                                        />
                                        <el-input
                                        v-model="opcion.texto"
                                        :placeholder="`Respuesta ${opcion.posicion}`"
                                        size="small"
                                        class="mb-1 w-80"
                                        show-word-limit
                                        maxlength="250"
                                        />
                                        <i class="icon-close cr-pointer ml-3 my-auto" @click="eliminarOpcionModal(opcion, pregunta, o)" />
                                    </div>
                                </draggable>
                            </div>
                        </div>
                        <div v-if="pregunta.tipo === 3" class="row mx-0 mt-2">
                            <!-- tipo -->
                            <div class="col-3">
                                <p class="color-general f-500 cr-pointer text-verde" @click="agregarOpcion(pregunta)">
                                    Agregar respuesta
                                </p>
                            </div>
                            <!-- pregunta -->
                            <div class="col-7">
                                <draggable
                                class=""
                                handle=".icon-vertical-points"
                                :disabled="!enabled"
                                @start="dragging = true"
                                @end="dragging = false"
                                >
                                    <div
                                    v-for="(opcion, id) in pregunta.opciones"
                                    :key="id"
                                    class="d-flex mb-2"
                                    >
                                        <i
                                        class="icon-vertical-points cr-pointer text-gris my-auto"
                                        />
                                        <el-input
                                        v-model="opcion.texto"
                                        :placeholder="`Respuesta ${opcion.posicion}`"
                                        size="small"
                                        class="mb-1 w-80"
                                        show-word-limit
                                        maxlength="250"
                                        />
                                        <i class="icon-plus cr-pointer ml-3 my-auto" />
                                        <i class="icon-close cr-pointer ml-3 my-auto" @click="eliminarOpcionModal(opcion, pregunta, id)" />
                                    </div>
                                </draggable>
                            </div>
                        </div>
                    </div>
                </draggable>
            </div>
            <div class="col-12 col-md-3 col-lg-2">
                <el-button class="btn btn-general f-300" @click="agregarPregunta">
                    Añadir pregunta
                </el-button>
            </div>
        </div>
        <modal-eliminar ref="abrirEliminarPregunta" titulo="Eliminar pregunta" mensaje="¿Desea eliminar esta pregunta?" tamano="modal-sm" @eliminar="eliminarPregunta" />
        <modal-eliminar ref="abrirEliminarOpcion" titulo="Eliminar opcion" mensaje="¿Desea eliminar esta opcion?" tamano="modal-sm" @eliminar="eliminarOpcion" />
    </div>
</template>
<script>
import Encuestas from '~/services/encuestas'
export default {
    components:{
    },
    data(){
        return{
            tipoPreguntaSelect: [{
                value: 1,
                label: 'Pregunta abierta',
            },
            {
                value: 2,
                label: 'Selección única',
            },
            {
                value: 3,
                label: 'Selección multiple',
            }],
            tipoPregunta: '',
            switchObligatorio: false,
            //draggable ejemplo
            enabled: true,
            dragging: false,

            pregunta_base : {},

            preguntas: [],
            preguntaEliminar: {},
            opcionEliminar: {}
        }
    },
    created(){
        this.listarPreguntas()
    },
    methods:{
        eliminarPreguntaModal(pregunta, index){
            this.preguntaEliminar = pregunta
            if(typeof this.preguntaEliminar.id != 'undefined'){
                this.$refs.abrirEliminarPregunta.toggle()
            }else{
            //    // Eliminar las que no son remotas
                this.preguntas.splice(index, 1)
            }
            this.indicadorTotalPreguntas()
        },
        eliminarOpcionModal(opcion, pregunta, index){
            this.opcionEliminar = opcion
            if(typeof this.opcionEliminar.id != 'undefined'){
                this.$refs.abrirEliminarOpcion.toggle()
            }else{
                pregunta.opciones.splice(index, 1) // Eliminar las que no son remotas
            }
        },
        agregarPregunta(){

            // se validan que las preguntas tengan títulos
            const tituloNoEsNull = (el) => el.titulo
            let validarTitulos = this.preguntas.every(el=>tituloNoEsNull(el))

            if(!validarTitulos && this.preguntas.length) return this.notificacion('','Por favor escribe un título para la pregunta')
            let pregunta = {
                id_encuesta:this.$route.params.id_encuesta,
                obligatoria:0,
                posicion:this.preguntas.length + 1,
                tipo:1,
                titulo:null,
                error:false
            }
            this.preguntas.push(pregunta)
            this.indicadorTotalPreguntas()
        },
        agregarOpcion(pregunta){
            // validamos si previamente tiene alguna opción nula
            if(typeof pregunta.opciones != 'undefined'){
                const textoEsNull = (el) => !el.texto
                let validate = pregunta.opciones.some(el=>textoEsNull(el))
                if(validate) return this.notificacion('','Por favor escribe un título a la respuesta')  
            } 

            let opcion = {
                posicion : (typeof pregunta.opciones != 'undefined') ? pregunta.opciones.length + 1 : 1,
                texto : null,
            }
            if(typeof pregunta.opciones != 'undefined'){
            // pregunta.opciones[new_index] = opcion
                pregunta.opciones.push(opcion)
            
            }else{
                this.$set(pregunta, 'opciones', [])
                pregunta.opciones = [opcion]
            }

        },
        async eliminarPregunta(){
            try {
                this.loading = true
                let params = {
                    id_pregunta : this.preguntaEliminar.id
                }
                const {data} = await Encuestas.eliminarPregunta(params)
                this.$refs.abrirEliminarPregunta.toggle()
                this.listarPreguntas()
            } catch (e){
                this.errorCatch(e)
                this.loading = false
            }
        },
        async eliminarOpcion(){ 
            try {
                this.loading = true
                let params = {
                    id_opcion : this.opcionEliminar.id
                }
                const {data} = await Encuestas.eliminarOpcion(params)
                this.listarPreguntas()
            } catch (e){
                this.errorCatch(e)
                this.loading = false
            }
        },
        indicadorTotalPreguntas(){
            this.$emit('total_preguntas',this.preguntas.length)
        },
        async listarPreguntas(){
            try {
                this.loading = true
                const {data} = await Encuestas.listarPreguntas(this.$route.params.id_encuesta)
                this.preguntas = data.data

                this.preguntas.map(el=>{el.error = false; return el})
                this.$emit('total_preguntas', this.preguntas.length)
                this.loading = false
            } catch (e){
                this.errorCatch(e)
                this.loading = false
            }
        },
        getPreguntas(){
            return this.preguntas
        },
        setPreguntas(preguntas){
            this.preguntas = preguntas
        },
        mostrarPreguntasNoValidas(preguntasArray){
            preguntasArray.forEach((element) => {
                this.preguntas[element].error = true
            });

            setTimeout(() => {
                this.preguntas.map(function(el){
                    el.error = false
                    return el
                })
            }, 10000);
        }

    }
}
</script>
<style lang="scss" scoped>
.encuesta-crear2{
    .btn-general{
        min-width: 86px;
    }
    .n-preguntas{
        background: #000;
        color: #fff;
        width: 17px;
        min-width: 17px;
        height: 17px;
        border-radius: 50%;
    }
    .card-pregunta-encuesta{
        border-radius: 12px;
        border: 1px solid #DBDBDB;
        .n-pregunta{
            border-radius: 12px;
            background: #000;
            color: #fff;
            max-width: 34px;
            min-width: 34px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: 500;
        }
        .set-draggable{
            position: absolute;
            top: 0px;
            right: 50%;
            i:first-child{
                position: relative;
                left: 14px;
            }
        }
        .text-verde{
            color: #109881;
        }
    }
}
</style>